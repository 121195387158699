<template>
    <el-row>
        <!-- 多云管理平台-->
        <fixed-item></fixed-item>

        <div class="itsm-header">
            <div class="header-title1">伊登云·跨平台</div>
            <div class="header-title2">eITSM工单管理系统</div>
<!--            <div class="header-btn">-->
<!--                <div class="btn-text">-->
<!--                    在线咨询-->
<!--                    <img src="../../img/product/bpm/icon1.svg">-->
<!--                </div>-->
<!--            </div>-->
        </div>

        <div class="itsm-content">
            <div class="content1-img">
                <div>
                    <img src="../../img/product/bpm/content_img1.png">
                </div>
                <p class="img1-text">伊登ITSM工单管理系统 是一套将工作流程标准化，实现服务管理的协同性、便捷性和高效率的工单管理平台。</p>
            </div>

            <div class="content2-module">
                <div class="module-text1">您的企业为什么需要它</div>
                <p class="module-text2">伊登eITSM工单管理系统具备以多渠道发布工单、智能派单、工单处理、客户评价等能力，完成整个服务管理的闭环，并可将工单一键转知识，帮助企业大大提高服务资源的利用率，节约服务运营成本，提升服务品质，提高客户满意度。</p>
<!--                <div class="module-btn">-->
<!--                    <div class="btn-text">-->
<!--                        在线咨询-->
<!--                        <img src="../../img/product/cloudManage/icon2.svg">-->
<!--                    </div>-->
<!--                </div>-->
            </div>

            <div class="content3-background">
                <div class="content3-img">
                    <img src="../../img/product/itsm/content_img2.png" alt="">
                </div>
            </div>

            <div class="content4-list">
                <div class="list-title">协作，让团队灵活高效</div>
                <div class="list-content">工单工作流，让团队高效的完成任务，让企业快速的提升效率</div>
                <div class="list-items">
                    <div class="list-item">
                        <div>
                            <div class="item-num">1</div>
                            <div class="item-content">业务流程通过简单配置字段和触发机制即可完成高度自定义，无需代码</div>
                        </div>
                    </div>
                    <div class="list-item">
                        <div>
                            <div class="item-num">2</div>
                            <div class="item-content">工单系统页面信息根据企业需求进行配置化自定义，打造只属于企业的专有页面</div>
                        </div>
                    </div>
                    <div class="list-item">
                        <div>
                            <div class="item-num">3</div>
                            <div class="item-content">设置自动化流程控制，让智能代替人工执行任务，释放人力</div>
                        </div>
                    </div>
                    <div class="list-item">
                        <div>
                            <div class="item-num">4</div>
                            <div class="item-content">流程每个节点都可以追溯，计时，企业的管理者可以实时看到流程处理情况，满足合规性</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="content5-module">
                <div class="module-title">跨平台多渠道处理，使企业高效运转</div>
                <div class="module-content">通过teams、web、移动端等多种渠道提交工单，形成统一的票证，让信息汇聚一处具有sdk、api、前端嵌入等丰富的集成手段，让工作流与企业的系统无缝对接工单一键处理，让所有的动作集成为一个按钮，企业服务从未如此给力简单配置分配规则，可以让工单根据企业需求自动流转，分配，让工作精准高</div>
            </div>

            <div class="content6-module">
                <div class="module-title">线上线下，多端支持客户服务</div>
                <div class="module-content">企业可以在PC、手机、平板电脑等，通过Teams、web、app、H5处理工单信息支持线上派单，线下处理，打通虚拟和现实的隔阂，让服务无处不在线下工单追踪，售后客户反馈，让客户体验有迹可循</div>
                <div class="text-center"><img src="../../img/product/itsm/content_img3.png"></div>
            </div>

            <div class="content7-module">
                <div class="module-title">丰富的数据展现，安全且实用</div>
                <div class="module-content">每条工单不仅包括丰富的业务信息，也会整合相关的客户、公司、业务等多个维度的数据，信息全面，一览无余提供超过十几种数据报表，让每个工单的处理成果都有数据展现符合标准的加密方式，让数据在工单管理系统中安全流转</div>
            </div>



            <div class="content8-service">
                <div class="service-title">完善的售后服务</div>
                <div class="service-items">
                    <div class="service-item">
                        <div class="item-text">本产品由深圳伊登软件有限公司（销售主体）为您优选，并提供服务和发票，由阿里云授权华安视讯科技提供产品技术支持和运维服务</div>
                    </div>
                    <div class="service-item">
                        <div class="item-text">在质保期内若产品制造质量出现问题，享受7日内退货，15日内换货，15日以上在质保期内享受免费保修等三包服务</div>
                    </div>
                    <div class="service-item">
                        <div class="item-text">正常的设备维护保养工作由用户解决，随时提供远程指导</div>
                    </div>
                    <div class="service-item">
                        <div class="item-text">在质保期内，我公司对客户由于使用不当或者人为原因造成材料、设备的损坏，免费保修，只收材料、设备成本费。</div>
                    </div>
                </div>
            </div>

            <div class="content9-footer">
<!--                <div class="footer-try">在线试用</div>-->
            </div>
        </div>
    </el-row>
</template>

<style scoped lang="scss">
* {
    font-size: 14px;
}
.itsm-header {
    .header-title1 {
        font-size: 48px;
        font-weight: 600;
        color: #4050B5;
        text-align: center;
        margin-top: 3%;
    }
    .header-title2 {
        font-size: 30px;
        font-weight: 400;
        color: #4050B5;
        text-align: center;
        margin-top: 1.5%;
    }
    .header-btn {
        width: 10%;
        height: 44px;
        display: flex;
        align-items: center;
        text-align: center;
        border-radius: 22px;
        border: 1px solid #4050B5;
        margin: 1.5% auto 0%;
        cursor: pointer;
        .btn-text {
            width: 100%;
            text-align: center;
            font-size: 20px;
            font-weight: 500;
            color: #4050B5;
        }
    }
}

.itsm-content {
    background: url("../../img/product/itsm/bg_3.png") no-repeat top center;
    margin-top: 8%;
    .content1-img {
        text-align: center;
        width: 40%;
        margin: -6% auto 0%;
        .img1-text {
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 28px;
        }
    }
    .content2-module {
        width: 44%;
        margin: 6% auto 0%;
        .module-text1 {
            font-size: 40px;
            font-weight: 600;
            color: #FFFFFF;
            text-align: center;
        }
        .module-text2 {
            font-size: 16px;
            font-weight: 500;
            color: #FFFFFF;
            text-align: center;
            margin-top: 5%;
            line-height: 28px;
        }
        .module-btn {
            width: 22%;
            height: 44px;
            display: flex;
            align-items: center;
            border-radius: 22px;
            border: 1px solid #FFFFFF;
            margin: 5% auto 0%;
            cursor: pointer;
            .btn-text {
                width: 100%;
                text-align: center;
                font-weight: 500;
                font-size: 20px;
                color: #FFFFFF;
            }
        }
    }
    .content3-background {
        background: rgba(33,33,33,0.2000);
        padding: 2% 0%;
        margin-top: 5%;
        .content3-img {
            text-align: center;
        }
    }
    .content4-list {
        margin-top: 2%;
        .list-title {
            font-size: 40px;
            font-weight: 600;
            color: #FFFFFF;
            text-align: center;
        }
        .list-content {
            font-size: 18px;
            font-weight: 400;
            color: #FFFFFF;
            text-align: center;
            margin-top: 2%;
        }
        .list-items {
            display: flex;
            align-items: center;
            width: 60%;
            margin: 3% auto;
            .list-item {
                width: 256px;
                height: 245px;
                background: #FFFFFF;
                border-radius: 8px;
                margin: 1%;
                .item-num {
                    font-size: 32px;
                    font-weight: 600;
                    color: #3764D7;
                    text-align: center;
                    margin-top: 15%;
                }
                .item-content {
                    font-size: 16px;
                    font-weight: 400;
                    color: #414141;
                    line-height: 24px;
                    text-align: center;
                    margin: 10%;
                }

            }
        }
    }
    .content5-module {
        text-align: center;
        background: url("../../img/product/itsm/bg_1.png") no-repeat top center;
        padding: 8% 0%;
        .module-title {
            font-size: 40px;
            font-weight: 500;
            color: #FFFFFF;
        }
        .module-content {
            font-size: 16px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 41px;
            width: 45%;
            margin: 3% auto 0%;
        }
    }
    .content6-module {
        text-align: center;
        background: #FFFFFF;
        padding: 6% 0%;
        .module-title {
            font-size: 40px;
            font-weight: 600;
            color: #414141;
        }
        .module-content {
            font-size: 16px;
            font-weight: 400;
            color: #414141;
            line-height: 40px;
            width: 50%;
            margin: 3% auto;
        }
    }
    .content7-module {
        background: url("../../img/product/itsm/bg_2.png") no-repeat top center;
        text-align: center;
        padding: 9% 0%;
        .module-title {
            font-size: 40px;
            font-weight: 500;
            color: #FFFFFF;
        }
        .module-content {
            color: #FFFFFF;
            font-size: 16px;
            font-weight: 500;
            line-height: 40px;
            width: 50%;
            margin: 3% auto;
        }
    }

    .content8-service {
        margin-top: 5%;
        .service-title {
            text-align: center;
            font-size: 40px;
            font-weight: 500;
            color: #414141;
        }
        .service-items {
            display: flex;
            flex-wrap: wrap;
            width: 70%;
            margin: 3% auto;
            .service-item {
                padding: 9% 5%;
                margin: 1%;
                width: 38%;
                display: flex;
                align-items: center;
                box-shadow: 0px 0px 17px 0px rgba(33,33,33,0.1000);
                .item-text {
                    font-size: 16px;
                    font-weight: 500;
                    color: #414141;
                    line-height: 28px;
                    text-align: center;
                    width: 100%;
                }
            }
        }
    }

    .content9-footer {
        background: url("../../img/product/cloudManage/bg_4.png") no-repeat center;
        height: 225px;
        display: flex;
        align-items: center;
        .footer-try {
            width: 10%;
            height: 20%;
            background: #FFFFFF;
            border-radius: 28px;
            display: flex;
            justify-content:center;
            align-items:center;
            margin: auto;
            cursor: pointer;
        }
    }
}
</style>

<script>
import FixedItem from "../../compoents/fixed/fixedItem";
export default {
    name: "productITSM",
    components: {FixedItem},
    data() {
        return {

        }
    },
    mounted() {

    },
    methods: {

    }
}
</script>