<template>
    <el-row>
        <div class="right-fixed">
            <div class="fixed-list">

                <div class="fixed-item" @mouseenter="ifShow('ifPhone')" @mouseleave="ifShow('ifPhone')">
                    <div class="item-img"><img src="../../img/product/bpm/phone.svg" alt=""></div>
                    <div class="item-title">电话咨询</div>
                    <div class="fixed-modal phone-top" v-if="ifPhone">
                        <div>400-830-0095</div>
                    </div>
                </div>
                <div class="fixed-item" @mouseenter="ifShow('ifCode')" @mouseleave="ifShow('ifCode')">
                    <div class="item-img"><img src="../../img/product/bpm/wechat.svg" alt=""></div>
                    <div class="item-title">微信资讯</div>
                    <div class="fixed-modal code-top" v-if="ifCode">
                        <div>
                            <div>请扫描二维码</div>
                            <div>
                                <img src="@/img/home/ewm.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="fixed-item" @click="toTop">
                    <div class="item-img"><img src="../../img/product/bpm/like.svg" alt=""></div>
                    <div class="item-title">回到顶部</div>
                </div>
            </div>
        </div>
    </el-row>
</template>

<script>
export default {
    name: "fixedItem",
    data() {
        return {
            ifPhone: false,
            ifCode: false
        }
    },
    methods: {
        ifShow(key) {
          this[key] = !this[key];
        },
        toTop() {
            document.documentElement.scrollTop = 0;
        }
    }
}
</script>

<style scoped lang="scss">
* {
    font-size: 14px;
}
.right-fixed {
    position: fixed;
    right: 0;
    top: 30%;
    z-index: 99999999999999999999;
    .fixed-list {
        position: relative;

        .fixed-item {
            text-align: center;
            width: 80px;
            height: 103px;
            background: #3764D7;
            padding: 5px;
            cursor: pointer;
            position: relative;
            .item-img {
                margin-top: 24px;
            }
            .item-title {
                margin-top: 8px;
                color: #FFFFFF;
            }
            .fixed-modal {
                position: absolute;
                left: -140px;
                background: white;
                padding: 10%;
                text-align: center;
                border-radius: 5px;
            }
            .phone-top {
                top: 30%;
            }
            .code-top {
                top: -15%;
            }
        }
        .fixed-item:hover {
            background: rgba(43,79,171,1);
        }
    }

}
</style>